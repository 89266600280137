import Routes from "./Routes/Routes";
import { useLayoutEffect } from "react";
import { Toaster } from "react-hot-toast";
import i18next from "i18next";
import { connect } from "react-redux";
import { setSelectedLanguages, setUserDetail } from "./Redux/actions";
import "./App.css";
import { useEffect } from "react";
import { setUserAuthentication } from "./Redux/actions";
import { fetchUserDetailsApi } from "./APIS/AuthApis";

function App({
  setSelectedLanguagesRedux,
  getUserAuthenticationRedux,
  setUserDetailRedux,
}) {
  useLayoutEffect(() => {
    let languageStorage = localStorage.getItem("language");
    i18next.changeLanguage(languageStorage ? languageStorage : "en");
    setSelectedLanguagesRedux(languageStorage ? languageStorage : "en");
  }, []);

  useEffect(() => {
    if (getUserAuthenticationRedux) {
      fetchUserDetailsApi(getUserAuthenticationRedux, (data) => {
        setUserDetailRedux(data);
      });
    }
  }, []);

  return (
    <div>
      <Toaster />
      <Routes />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedLanguagesRedux: (parameter) => {
      dispatch(setSelectedLanguages(parameter));
    },
    setUserAuthenticationRedux: (parameter) => {
      dispatch(setUserAuthentication(parameter));
    },
    setUserDetailRedux: (parameter) => {
      dispatch(setUserDetail(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
