import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";

export const getParentCategory = (language, callback) => {
  let data = JSON.stringify({
    language: language,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-parent-categories`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(response)
      callback(response.data.data.categories);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getAllProductsHomeApi = (getSelectedLanguage, callback) => {
  let data = JSON.stringify({
    language: getSelectedLanguage,
    // uid: 8,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-products-home`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      callback(response.data.data.homeProduct);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getSingleProduct = (id, lang, callback) => {
  let data = JSON.stringify({
    productId: id,
    language: lang,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}single-product`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchCategoryProducts = (formdata, callback) => {
  var data = JSON.stringify(formdata);

  var config = {
    method: "post",
    url: `${baseUrl}fetch-category-products`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      // console.log(response,"111111111")
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const Searchproduacts = (
  getSelectedLanguage,
  searchvalue,
  id,
  callback
) => {
  var data = JSON.stringify({
    page: 1,
    language: getSelectedLanguage,
    uid: id || "",
    search: searchvalue,
  });

  var config = {
    method: "post",
    url: `${baseUrl}search-product`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const FetchAddressApi = (token, formdata, callback) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-all-address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const DeleteAddressApi = (
  token,
  addressId,
  FetchAddressApi,
  formdata,
  callback
) => {
  let data = JSON.stringify({ addressId });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}delete-address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      SuccessToaster(response?.data?.message);
      FetchAddressApi(token, formdata, callback);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);

      console.log(error);
    });
};
export const EditAddressApi = (
  token,
  input,
  FetchAddressApi,
  formdata,
  callback
) => {
  let data = JSON.stringify(input);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update-address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      SuccessToaster(response?.data?.message);
      FetchAddressApi(token, formdata, callback);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);

      console.log(error);
    });
};
export const orderCreate = (token, payItem, navigation) => {
  let data = JSON.stringify(payItem);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}order-create`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      navigation("/");
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);

      console.log(error);
    });
};

export const orderIteamFetch = (token, formdata, callback) => {
  let data = JSON.stringify(formdata);


  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}order-items-fetch`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const orderCancelApi = (token, formdata, Data,navigation) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}order-cancel`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // callback(response?.data?.status)
      SuccessToaster(response?.data?.message);
      orderIteamFetch(token, Data);
      // navigation('/')
      // window.location.reload()
      //  console.log(response)
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);

      console.log(error);
    });
};

export const ContactusApi = (token, formdata) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}contact-us`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      // console.log(response);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);

      console.log(error);
    });
};
export const NotificationApi = (token, Formdata,callback) => {
  let data = JSON.stringify(
    Formdata
  );

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-notifications`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // SuccessToaster(response?.data?.message);

      // console.log(response);
      callback(response?.data.data)
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);

      console.log(error);
    });
};
