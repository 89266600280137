import React, { useState, useEffect } from "react";
import Code from "../Code";
import { connect } from "react-redux";
import { addAddressApi } from "../APIS/AuthApis";
import "./../Styles/profile.css";
import { ErrorToaster } from "../Toaster";
import { useTranslation } from "react-i18next";



function ProfileAddAddress({getUserAuthenticationRedux}) {
  const [selectCountryCode, setSelctCountryCode] = useState("+91");
  const [addressType, setAddressType] = useState('Home')
  const { t } = useTranslation();

  const [ addressData, setAddressData] = useState({
    name: '',
    mobile: '',
    phone_code: selectCountryCode,
    phone_country:selectCountryCode,
    street: '',
    landmark: '',
    state:'',
    city: '',
    code: '',
    address_type: addressType,
    default: 1
  })



  const handleAddAddress = (e) => {
    e.preventDefault();


    if(addressData.name==='' || addressData.address_type==='' || addressData.city==='' || addressData.landmark==='' || addressData.mobile===''){
      ErrorToaster("All field is requried")
    }else if(addressData?.code?.length>='7' || addressData?.code==='' ){
      ErrorToaster("Code is Less than 7 digit")

    }
    else if(addressData?.mobile?.length<='7' || addressData?.mobile?.length>='13'){
      ErrorToaster("Mobile Number 8 to 12")
    }
    else{
      addAddressApi(getUserAuthenticationRedux ,addressData)
    }
  };

  const handleOnChange =(e)=>{
    setAddressData({...addressData, [e.target.name]:e.target.value})
  }
  
  return (
    <div>
      <form onSubmit={(e) => handleAddAddress(e)}>
        <div className="input-div-profile">
          <label htmlFor="Name">{t('Name')}</label>
          <input
            type="text"
            placeholder={t('Name')}
            className=""
            id="name"
            name="name"
            value={addressData?.name}
            onChange={handleOnChange}
          />
        </div>

        <div className="profile-mobile-field">
          <h6 htmlFor="phone">{t('MobileNumber')}</h6>
          <div className="row d-flex align-items-center">
            <div className="col-md-3">
              <select
                className="form-select"
                aria-label="Default select example"
                value={selectCountryCode}
                onChange={(e) => setSelctCountryCode(e.target.value)}
              >
                <option selected>{selectCountryCode}</option>
                {Code?.map((ele, i) => {
                  return (
                    <option key={i} value={ele.dial_code}>
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-div-profile col-md-9">
              <input
                type="Number"
                placeholder="+91 888888888"
                className=""
                id="phone"
                name='mobile'
                value={addressData?.mobile}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </div>

        <div className="input-div-profile m-0 p-0">
          <label htmlFor="Street">{t('Street')}</label>
          <input type="text" placeholder={t('Street')} name='street' className="" id="Street"  value={addressData?.street} onChange={handleOnChange}/>
        </div>
        <div className="input-div-profile">
          <label htmlFor="Landmark">{t('LandMark')}</label>
          <input
            type="text"
            placeholder={t('LandMark')}
            className=""
            id="Landmark"
            name='landmark'
            value={addressData?.landmark}
            onChange={handleOnChange}
          />
        </div>

        <div className="d-flex m-0 p-0">
          <div className="input-div-profile w-50 m-0 p-0">
            <label htmlFor="State">{t('State')}</label>
            <input type="text" placeholder={t('State')} className="" id="State" name='state' value={addressData?.state} onChange={handleOnChange} />
          </div>
          <div className="input-div-profile w-50 mx-1 m-0 p-0">
            <label htmlFor="City">{t('City')}</label>
            <input type="text" placeholder={t('City')} className="" id="City" name='city' value={addressData?.city} onChange={handleOnChange} />
          </div>
        </div>
        <div className="input-div-profile">
          <label htmlFor="Code">{t('PinCodeAddress')}</label>
          <input
            type="Number"
            placeholder={t('PinCodeAddress')}
            className=""
            id="Code"
            name='code'
            value={addressData?.code}
            onChange={handleOnChange}
          />
        </div>

        <div className="profile-address-radio-buttons">
          <h6 htmlFor="Address">{t('AddressType')}</h6>
          <div className="d-flex ">
            <div className="form-check ">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onClick={(e)=>{setAddressType('Home'); console.log(e)}}
                checked ={addressType ==='Home'? true:false}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                {t('AddressHome')}
              </label>
            </div>
            <div className="form-check mx-5 ">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                onClick={(e)=>{setAddressType('Office'); console.log(e)}}
                checked ={addressType ==='Office'? true:false}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
              {t('AddressOffice')}
              </label>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="btn text-white d-flex justify-content-center align-items-center w-100 save-detalis"
        >
          {t('ADDADDRESS')}
        </button>
      </form>
    </div>
  );
}



const mapStateToProps = (state) => {
    return {
      getUserAuthenticationRedux: state.userAuthentication.data,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
   
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProfileAddAddress);
  

