import React from "react";
import Slider from "@mui/material/Slider";
import Dropdown from "react-bootstrap/Dropdown";
import RadioGroup from '@mui/material/RadioGroup';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';

import { IoIosClose } from "react-icons/io";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { FiSearch } from "react-icons/fi";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from '@mui/material/Radio';

import { SliderThumb, SliderValueLabelProps } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

import { FaChevronDown } from "react-icons/fa";
import "../Styles/filters.css";

const checkBoxStyle = {
  color: "grey",
  "&.Mui-checked": {
    color: "#aa8c72",
  },
  padding:0.7
};
// MuiSlider-root
function valuetext(value) {
  return `${value}`;
}
function Filters({setMaxprice,setMinprice,minprice,maxprice,setRating,setShow}) {
  const [value, setValue] = React.useState([minprice, maxprice]);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    console.log(newValue,'checkin')
    let min=newValue[0]
    let max=newValue[1]
    let minValue=(500000*(min/100)).toFixed(0);
    let maxValue=(500000*(max/100)).toFixed(0);
    console.log(minValue,"1111111")
    console.log(maxValue,"5566777")
    //  setValue([minValue,maxValue])
    setMaxprice(maxValue)
    setMinprice(minValue)
    // console.log(min,'0000')
    // console.log(max,'333333333')
    setValue(newValue);
  };
  // console.log(value)
  const handleClearprice=()=>{
    setMaxprice(500000)
    setMinprice(0)


  }
  const handleclearAll=()=>{
    setMaxprice(500000)
    setMinprice(0)
    setRating(0)
  }
  

  const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: "#aa8c72",
    height: 3,
    padding: "13px 0",
    "& .MuiSlider-thumb": {
      height: 27,
      width: 27,
      backgroundColor: "#fff",
      border: "1px solid currentColor",
      "&:hover": {
        boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
      },
      "& .airbnb-bar": {
        height: 9,
        width: 1,
        backgroundColor: "currentColor",
        marginLeft: 1,
        marginRight: 1,
      },
    },
    "& .MuiSlider-track": {
      height: 3,
    },
    "& .MuiSlider-rail": {
      color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
      opacity: theme.palette.mode === "dark" ? undefined : 1,
      height: 3,
    },
  }));
 
 
  return (
    <div className="mt-md-0 mt-3">
    <div className="d-md-none d-flex justify-content-end"> <IoIosClose style={{fontSize:"25px"}} onClick = {()=>setShow(false)}/></div>
      <div className="mt-0 py-2 filter-heading d-flex justify-content-between align-items-center px-4">
        <h4>{t('Filters')}</h4>
        <span onClick={handleclearAll}>{t('CLEARALL')}</span>
      </div>
      <div className="price-slider-filter">
        <div className="mt-5 price-slider-filter-title d-flex justify-content-between align-items-center px-4">
          <h4>{t('PRICE')}</h4>
          <span onClick={handleClearprice}>{t('CLEAR')}</span>
        </div>
        <div className="px-4 filterslider">
        <Box sx={{ width: 300 }}>
      <Slider
       getAriaLabel={(index) =>
              index === 0 ? "Minimum price" : "Maximum price"
            }
        value={value}
        onChange={handleChange}

        getAriaValueText={valuetext}

        style={{color:"#AA8C72",marginLeft:"10px"}}
      />
    </Box>

          
        </div>
        <div className="px-4 d-flex justify-content-between align-items-center">
          <Dropdown>
            <Dropdown.Toggle
              className="border border-dark px-2"
              variant=""
              id="dropdown-basic"
            >
              {minprice}$
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item >{t('Min')}</Dropdown.Item>
              <Dropdown.Item  onClick={()=>setMinprice(500)}>500 $</Dropdown.Item>
              <Dropdown.Item   onClick={()=>setMinprice(1000)}>1000 $</Dropdown.Item>
              <Dropdown.Item   onClick={()=>setMinprice(5000)}>5000 $</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <span className="text-secondary">To</span>
          <Dropdown >
            <Dropdown.Toggle
              className="border border-dark px-2"
              variant=""
              id="dropdown-basic"
            >
             {maxprice}$
            </Dropdown.Toggle>

            <Dropdown.Menu >
              <Dropdown.Item onClick={()=>setMaxprice(200)}>200 $</Dropdown.Item>
              <Dropdown.Item onClick={()=>setMaxprice(500)}> 500$</Dropdown.Item>
              <Dropdown.Item onClick={()=>setMaxprice(1000)}>1000 $</Dropdown.Item>
              <Dropdown.Item onClick={()=>setMaxprice(5000)}>5000 $</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="brand-filter mt-4 px-4">
       
      </div>

      <div className="discount-filter mt-4 px-4">
        <div className="mt-0 discount-slider-filter-title d-flex justify-content-between align-items-center">
          <h4>{t('Rating')}</h4>
          <span>
            <FaChevronDown />
          </span>
        </div>

        <div className="discount-filter-list">
          <FormGroup>
          <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="0"
        name="radio-buttons-group"
      >
        <FormControlLabel value="0" onClick={()=>setRating(0)} control={<Radio sx={checkBoxStyle}/>} label={t('Ratingfilterlabel0')} />
        <FormControlLabel value="1" onClick={()=>setRating(1)} control={<Radio sx={checkBoxStyle}/>} label={t('Ratingfilterlabel1')} />
        <FormControlLabel value="2" onClick={()=>setRating(2)} control={<Radio sx={checkBoxStyle} />} label={t('Ratingfilterlabel2')} />
        <FormControlLabel value="3" onClick={()=>setRating(3)} control={<Radio sx={checkBoxStyle}/>} label={t('Ratingfilterlabel3')} />
        <FormControlLabel value="4" onClick={()=>setRating(4)} control={<Radio sx={checkBoxStyle}/>} label={t('Ratingfilterlabel4')} />

      </RadioGroup>
           
            
            <h5>{t('FINDMORE')}</h5>
          </FormGroup>
        </div>

      </div>

    </div>
  );
}

export default Filters;
