import React, { useState, useRef } from "react";
import "../Styles/AddReview.css";
import { PiStarThin } from "react-icons/pi";
import { Rating } from "react-simple-star-rating";
import { AddReviewApi, UploadImageReviewApi } from "../APIS/ReviewApi";
import { SuccessToaster, ErrorToaster } from "../Toaster";
import { useTranslation } from "react-i18next";
import { GoPlusCircle } from "react-icons/go";

const AddReview = ({ id }) => {
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);


  let Relove_Token = localStorage.getItem("Relove_Token");

  const { t } = useTranslation();

  const [rating, setRating] = useState(0);
  const [image, setImage] = useState([]);
  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);


  const [sendimage, setSendimage] = useState([]);
  // console.log(image);
  // console.log(sendimage);
  const handleRating = (rate) => {
    setRating(rate);
  };
  const [input, setInput] = useState({
    title: "",
    description: "",
  });

  const handleInput = (e) => {
    let { name, value } = e.target;

    setInput({ ...input, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let Relove_Token = localStorage.getItem("Relove_Token");

    let formdata = {
      productId: id,
      title: input.title,
      description: input.description,
      images: sendimage,
      rating: rating,
    };

    // console.log(formdata);
    if (input.title === "" || input.description === "" || rating === "") {
      ErrorToaster("Enter the title,description,rating");
    } else if(! Relove_Token){
      ErrorToaster("please login")
    } else{
      AddReviewApi(Relove_Token, formdata);
      setImage("");
      setImage1("");
      setImage2("");
      setInput({ title: "", description: "" });
      setRating("");
    }
  };
  const handleImage = (e) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    formData.append("type", "review");
    // console.log(formData);
    if(!Relove_Token){
      ErrorToaster("You are not login please first one is login")
    }else{
    UploadImageReviewApi(Relove_Token, formData, (callback) => {
      setSendimage([...sendimage, callback.data.imgSend]);
      setImage(callback.data.img);
      // console.log(callback.data)

    })};
  };

  const handleUpdateImage = () => {
    inputRef.current.click();
    // console.log("hhhh")
  };
  const handleImage1 = (e) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    formData.append("type", "review");
    // console.log(formData);
    if(!Relove_Token){
      ErrorToaster("You are not login please first one is login")
    }else{
    UploadImageReviewApi(Relove_Token, formData, (callback) => {
      setSendimage([...sendimage, callback.data.imgSend]);
      setImage1(callback.data.img);
      // console.log(callback.data)
    })};
  };

  const handleUpdateImage1 = () => {
    inputRef1.current.click();
    // console.log("hhhh")
  };
  const handleImage2 = (e) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    formData.append("type", "review");
    // console.log(formData);
    if(!Relove_Token){
      ErrorToaster("You are not login please first one is login")
    }else{
    UploadImageReviewApi(Relove_Token, formData, (callback) => {
      setSendimage([...sendimage, callback.data.imgSend]);
      setImage2(callback.data.img);
      console.log(callback.data)

    })};
  };

  const handleUpdateImage2 = () => {
    inputRef2.current.click();
    // console.log("hhhh")
  };
  return (
    <div className="addreview">
      <h5>{t("AddReviewheading")}</h5>
      <div className="row ">
        <div className="d-flex addreview-star">
          <Rating
            onClick={handleRating}
            // onPointerEnter={onPointerEnter}
            // onPointerLeave={onPointerLeave}
            // onPointerMove={onPointerMove}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3 mt-3">
            <label for="exampleFormControlInput1" className="form-label">
              {t("AddReviewFeedback")}
            </label>
            <input
              type="text"
              className="form-control"
              name="title"
              value={input.title}
              onChange={handleInput}
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              {t("AddReviewMessage")}
            </label>
            <textarea
              className="form-control"
              type="text"
              rows="3"
              name="description"
              value={input.description}
              onChange={handleInput}
            ></textarea>
          </div>
          <div class="mb-3">
            <label for="formFileMultiple" className="form-label">
              {t("AddReviewChoosefile")}
            </label>
            <div className="d-flex">
              

              <div>
                <div
                  className="p-2 addreviewImag"
                 
                >
                  <img
                    src={image.length > 0 ? image : "../img/placeholder.png"}
                    type="file"
                    style={{
                      height: "100%",
                      width: "100%",
                      border: "1px solid #dfdfdf",
                    }}
                  />
                  <GoPlusCircle className="addreviewImagplus"
                    onClick={handleUpdateImage}

                  />
                </div>

                <input
                  type="file"
                  ref={inputRef}
                  onChange={handleImage}
                  style={{ display: "none" }}
                />
              </div>
              <div>
                <div
                  className="p-2 addreviewImag"
                 
                >
                  <img
                    src={image1.length > 0? image1 : "../img/placeholder.png"}
                    type="file"
                    style={{
                      height: "100%",
                      width: "100%",
                      border: "1px solid #dfdfdf",
                    }}
                  />
                  <GoPlusCircle className="addreviewImagplus"
                    onClick={handleUpdateImage1}

                  />
                </div>

                <input
                  type="file"
                  ref={inputRef1}
                  onChange={handleImage1}
                  style={{ display: "none" }}
                />
              </div>
              <div>
                <div
                  className="p-2 addreviewImag"
                  
                >
                  <img
                    src={image2.length > 0 ? image2 : "../img/placeholder.png"}
                    type="file"
                    style={{
                      height: "100%",
                      width: "100%",
                      border: "1px solid #dfdfdf",
                    }}
                  />
                  <GoPlusCircle className="addreviewImagplus"
                    onClick={handleUpdateImage2}

                  />
                </div>

                <input
                  type="file"
                  ref={inputRef2}
                  onChange={handleImage2}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <button
              type="submit"
              className="btn btn-success"
              style={{
                backgroundColor: "#F1EBE3",
                border: "#F1EBE3",
                color: "black",
              }}
            >
              {t("AddReviewSubmit")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddReview;
